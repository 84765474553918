var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Display',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" The Popover feature, which provides a tooltip-like behavior, can be easily applied to any interactive element via the "),_c('code',[_vm._v("<b-popover>")]),_vm._v(" component or "),_c('a',{attrs:{"href":"https://bootstrap-vue.org/docs/directives/popover","target":"_blank"}},[_vm._v("v-b-popover")]),_vm._v(" directive. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-title'),_c('p',{staticClass:"hp-p1-body mb-0"})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('positioning')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('popover-variants')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('show-and-hide')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('open-and-close')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('start-open')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('disabling')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('advanced')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }